<template>
  <div :class="[`unload-list__item ${colorType}`, { active }]">
    <unload-list-item-status :item="item" />
    <r-block
      space-between
      no-padding
      col
    >
      <r-title
        class="unload-list__item-title"
        type="subtitle-1"
        :title="item.name"
      >
        {{ `№ ${item.name}` }}
      </r-title>
      <r-text>
        {{ `${item.cargo_count} груз.` }}
      </r-text>
    </r-block>
  </div>
</template>

<script>
import { statusList } from '../../configs'

export default {
  components: {
    unloadListItemStatus: () => import('./unload-list-item-status')
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statusList
    }
  },
  computed: {
    status() {
      return this.statusList[this.item.status]
    },
    colorType() {
      return this.status?.color || 'default'
    },
    statusTitle() {
      return this.status?.title || 'без статуса'
    }
  }
}
</script>

<style lang="scss">
.unload-list__item {
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  background-color: var(--button_secondary_bg);
  border-radius: var(--border-radius);
  cursor: pointer;

  &-title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.accent-primary {
    background-color: var(--accent_hover);
  }

  &.accent-warning {
    background-color: var(--accent_warning_hover);
  }

  &.accent-success {
    background-color: var(--accent_success_hover);
  }

  &.accent-error {
    background-color: var(--accent_error_hover);
  }

  &.active {
    background-color: var(--accent_primary_2);
  }
}
</style>
